/* modal */
export const openModal = (modalType, modalProps, effect = "slide") => {
  return { type: "OPEN_MODAL", modalType, modalProps, effect };
};

export const closeModal = () => {
  return window.popupLogin ? false : { type: "CLOSE_MODAL" };
};

export const openModalWithCb = (
  modalType,
  callback = () => {},
  modalProps,
  effect
) => {
  return dispatch => {
    dispatch({
      type: "OPEN_MODAL",
      modalType,
      modalProps,
      effect,
    });
    // 在dispatch后执行回调
    callback();
  };
};
