import store from "@shared/scripts/utils/store";
import { openModal } from "@shared/scripts/actions/modal";
import { getDocumentFile } from "@shared/scripts/features/api";

export const isPad = () => {
  const ua = navigator.userAgent;
  const isAndroid = /(?:Android)/.test(ua);
  const isFireFox = /(?:Firefox)/.test(ua);
  return (
    /(?:iPad|PlayBook)/.test(ua) ||
    (isAndroid && !/(?:Mobile)/.test(ua)) ||
    (isFireFox && /(?:Tablet)/.test(ua))
  );
};
export const isMobile = () => {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent) || isPad();
};

export default () => {
  if (!isMobile()) {
    $("#js-get-document").on("click", function() {
      const { email, id } = this.dataset;
      getDocumentFile(id);
      store.dispatch(
        openModal("document", {
          email,
        })
      );
    });

    $("#js-pro-document").on("click", function() {
      const { url, apply_pending } = this.dataset;
      store.dispatch(openModal("charge", { url, apply_pending }));
    });
  } else {
    const $modal = $("#js-document-modal");
    const $modalContent = $("#js-modal-content");
    const $closeBtn = $(".js-close-modal");
    const closeModal = () => $modal.removeClass("is-show");

    $modal.on("tap", closeModal);

    $modalContent.on("tap", event => {
      event.stopPropagation();
    });

    $closeBtn.on("tap", closeModal);

    $("#js-get-document").on("tap", function() {
      getDocumentFile(this.dataset.id);
      $modal.addClass("is-show");
    });
  }
};
