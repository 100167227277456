/* eslint-disable no-param-reassign */
import remove from "lodash/remove";
import socialShare from "./social_share";

const $window = $(window);
const noLoop = () => {};

export const isMobile = () => {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent);
};

export const getPageId = () => $("body").attr("id");

/**
 * page run corresponding function
 * @param {array | string} path
 * @param {func} fn
 */
export const runPage = (path, fn) => {
  const pageID = getPageId();
  const map = {
    string: () => pageID === path,
    object: () => path.some(x => x === pageID),
  };
  const isAllow = map[typeof path]();

  if (isAllow) {
    fn();
  }
};

/**
 * remove the index item from array
 * @param {array} arr
 * @param {number} index
 */
export const removeIndexFromArr = (arr, index) => {
  arr.splice(index, 1);
  return arr;
};

/**
 * create ajax
 * @param {string} url
 * @param {string} method
 * @param {object} data
 * @param {string} datType
 * @param {bool} isAsync
 */
export const createAjax = ({
  url,
  method = "GET",
  data = {},
  isAsync = true,
  dataType = "json",
  Accept = "application/json",
}) => {
  return $.ajax({
    headers: {
      Accept,
    },
    async: isAsync,
    url,
    method,
    dataType,
    data,
  });
};

/**
 * sidebar style change when scroll
 * @param {jq element selector} sidebarSelector
 * @param {jq element selector} contentSelector
 * @param {bool} isContentHeightChange
 * @param {string} absoluteClass
 * @param {string} defaultClass
 */
export const sidebarWithScroll = ({
  sidebarSelector,
  contentSelector,
  isContentHeightChange = false,
  absoluteClass = "is-absolute",
  defaultClass = "",
  mountCondition = () => true,
}) => {
  const $siderbar = $(sidebarSelector);
  const sidebarAllHeight = $siderbar.height() + $siderbar.position().top;
  if (!mountCondition(sidebarAllHeight)) return;

  const $content = $(contentSelector);
  const getContentHeight = () => $content.height();
  const contentHeight = getContentHeight();

  $window.on("scroll", () => {
    const contentAllHeight =
      $content.offset().top +
      (isContentHeightChange ? getContentHeight() : contentHeight);
    const allHeight = Math.ceil(sidebarAllHeight + $window.scrollTop());

    if ($siderbar.hasClass(absoluteClass)) {
      if (allHeight < contentAllHeight) {
        $siderbar.removeClass(absoluteClass).addClass(defaultClass);
      }
    } else if (allHeight > contentAllHeight) {
      $siderbar.removeClass(defaultClass).addClass(absoluteClass);
    }
  });
};

/**
 * set target element is active, sibing node remove active
 * @param {jq element} target
 */
export const setOnlyTargetActive = $target => {
  $target
    .addClass("is-active")
    .siblings()
    .removeClass("is-active");
};

/**
 * set nav active according to where scollTop
 * @param {jq element} $contents
 * @param {jq element} $nav
 * @param {number} scrollTop
 */
export const setActiveNav = (
  $contents,
  $nav,
  scrollTop,
  offsetHeight = 0,
  currentActiveIndex = -1
) => {
  let activeIndex = 0;
  const realScrollTop = Math.ceil(scrollTop + offsetHeight);
  $contents.each((index, item) => {
    if (realScrollTop >= $(item).offset().top) {
      activeIndex = index;
    }
  });

  if (currentActiveIndex === activeIndex) return currentActiveIndex;
  setOnlyTargetActive($($nav[activeIndex]));
  return activeIndex;
};

/**
 * page scroll to target location
 * @param {number} location
 * @param {number} time
 */
export const pageScrollToTarget = (location = 0, time = 500) => {
  $("html, body").animate(
    {
      scrollTop: location,
    },
    time
  );
};

export const articleInfoNodesAction = (eventType = "click") => {
  const slideDuration = 300;
  const effect = ($current, activeClass, $target, removeAction, addAction) => {
    if ($current.hasClass(activeClass)) {
      $current.removeClass(activeClass);
      (removeAction || noLoop)();
      $target
        .stop(true, true)
        .fadeOut({ duration: slideDuration, queue: false })
        .slideUp(slideDuration);
    } else {
      $current.addClass(activeClass);
      (addAction || noLoop)();
      $target
        .stop(true, true)
        .fadeIn({ duration: slideDuration, queue: false })
        .css("display", "none")
        .slideDown(slideDuration);
    }
  };

  $(".js-tips-header").on(eventType, function() {
    const $this = $(this);
    effect($this, "is-open", $this.next());
  });

  $("#js-switch-nodes").on(eventType, function() {
    const $this = $(this);
    effect(
      $this,
      "t-expand",
      $("#js-hidden-nodes"),
      () => {
        $("#js-switch-text").text("展开全部数据");
      },
      () => {
        $("#js-switch-text").text("收起部分数据");
      }
    );
  });
};

/**
 * get scrollbar width
 *
 */
export const scrollBarWith = () => {
  let fullWindowWidth = window.innerWidth;
  if (!fullWindowWidth) {
    const documentElementRect = document.documentElement.getBoundingClientRect();
    fullWindowWidth =
      documentElementRect.right - Math.abs(documentElementRect.left);
  }
  const width = fullWindowWidth - document.body.clientWidth;
  return width;
};

/**
 * use timeago
 *
 */
export const timeago = () => {
  $(".js-time-ago").timeago();
};

/**
 * switch tab
 *
 */
export const switchTab = (actionType = "click") => {
  const $tabItem = $(".js-u-tab");
  const $contentItem = $(".js-u-item");
  $(window).scroll();

  $tabItem.on(actionType, function() {
    const $this = $(this);
    const index = $this.data("index") || $this.index();
    const item = $contentItem[index];

    setOnlyTargetActive($this);
    setOnlyTargetActive($(item));
    $(window).scroll();
  });
};

/**
 * sidebar dropdowm
 *
 */
export const sidebarDropDown = () => {
  const $mainItem = $(".js-sidebar-main");

  $mainItem.on("click", () => {
    $(".js-main-container").toggleClass("is-open");
  });
};

/**
 * not submit when value is null or ''
 * @param {element} el
 */
export const FilterInForm = el => {
  const value = $(el).val();
  if (value === "" || value === null) {
    return null;
  }

  return el;
};

/**
 * not submit when value is null or ''
 * @param {element} el
 */

export const rightDirectory = (
  selector = "#js-article-content",
  tag = "h2",
  offset = 0
) => {
  const $directoryItem = $(selector).find(tag);
  const $topicDirectory = $(".js-topic-directory");
  if ($directoryItem.length === 0) {
    $topicDirectory.remove();
    return;
  }

  const $driectoryContainer = $(".js-directory-container");

  let fragment = "";
  $directoryItem.each((index, text) => {
    fragment += `<a class="topic-directory__item" data-index=${index} href="javascript:;">${$(
      text
    ).text()}</a>`;
  });
  $(".js-directory-list").append(fragment);

  $topicDirectory.on("click", () => {
    $driectoryContainer.toggleClass("is-active");
  });

  $(".js-directory-list").on("click", ".topic-directory__item", function() {
    const $this = $(this);
    pageScrollToTarget($($directoryItem[$this.index()]).offset().top - offset);
  });
};

/**
 * share article,daily
 */
export const shareAction = (url = "") => {
  const $btn = $(".js-share-btn");
  $btn.on("click", function() {
    const $this = $(this);
    const type = $this.data("type");
    const text = $(this).data("title") || "";
    socialShare.run({ type, url, text });
  });
};

/**
 * bottom bar action when scroll
 * @param {string} seletor
 */
const bottomBarBindScoll = () => {
  const $bottombar = $(".js-bottombar");
  const $content = $(".js-article-other .js-article-action");
  const barTop = $bottombar.offset().top;
  const { top } = $content.offset();
  // let timeout = null;
  // const $translate = $('.js-translate-block');
  // const $translateClose = $('.js-close-translate');

  if (barTop < top) {
    $bottombar.removeClass("is-show");
  } else {
    $bottombar.addClass("is-show");
  }

  // $translate.removeClass('is-show');
  // clearTimeout(timeout);
  // timeout = setTimeout(() => {
  //   $translate.addClass('is-show');
  // }, 500);
};

/**
 * bottom bar action in article modal
 * @param {string} seletor
 */
export const bottomBarActionInModal = seletor => {
  const $container = $(seletor);
  $container.on("scroll", bottomBarBindScoll);
  $container.scroll();

  $(".js-go-comment").on("click", () => {
    $container.animate(
      {
        scrollTop: $("#js-article-all").height(),
      },
      1000
    );
    $container.find(".js-open-editor").click();
  });
};

/**
 * bottom bar action in page
 */
export const bottomBarActionInPage = () => {
  $window.on("scroll", bottomBarBindScoll);
  $window.scroll();

  $(".js-go-comment").on("click", () => {
    pageScrollToTarget($("#comment").offset().top, 1000);
    $(".js-open-editor").click();
  });
};

/**
 * click to updating rucaptcha
 */
export const updateRucaptcha = () => {
  const $rucaptcha = $(".js-rucaptcha-image");
  $rucaptcha.attr("src", $rucaptcha.attr("src"));
};

/**
 * get value from url query
 * @param {string} url
 */

export const getUrlQuery = url => {
  const query = url.replace(/^\?/, "");
  const result = {};

  query.split("&").forEach(exp => {
    const [key, value] = exp.split("=");
    result[key] = value;
  });

  return result;
};

/**
 * waterfalls flow for window
 * @param {jquery node} $target
 * @param {func} cb
 */
export const waterFall = ($target, cb) => {
  $window.on("scroll", () => {
    if ($target.height() === 0) return;

    const nowScroll = $window.scrollTop();
    const targetAllHeight = $target.height() + $target.offset().top;

    if (Math.ceil(nowScroll + $(window).height()) >= targetAllHeight) cb();
  });
};

export const scrollTop = () =>
  window.pageYOffset ||
  document.documentElement.scrollTop ||
  document.body.scrollTop ||
  0;

/**
 * for clickAtOrigin detect
 */
const waitListen = [];

$(document).on("click", e => {
  waitListen.forEach(x => {
    const dom = x[0].get(0);
    if (dom === undefined) return;
    if (!(dom === e.target || $.contains(dom, e.target))) x[1](dom);
  });
});

export const clickAtOrigin = ($wrapper, cb) => {
  waitListen.push([$wrapper, cb]);
};

export const clickRemoveOrigin = $wrapper => {
  remove(waitListen, item => item[0].get(0) === $wrapper.get(0));
};

/**
 * image file to base64
 * @param {event object} event
 */
export const readImageFile = event => {
  event.preventDefault();
  const target = event.dataTransfer || event.target;
  const { files } = target;

  return new Promise((resolve, reject) => {
    if (files.length <= 0) {
      reject("文件为空");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        resolve(reader.result);
      };
    }
  });
};

/**
 * base64 to boundary
 * @param {base64} base64
 * @param {string} boundary
 * @param {string} name
 * @param {string} filename
 */
export const multipart = (
  base64,
  boundary = "customFileboundary",
  name,
  filename = "blob"
) => {
  let contentType = "image/png";
  let suffix = "png";
  const pureBase64ImageData = base64.replace(
    /^data:(image\/.+);base64,/,
    (_, $1) => {
      contentType = $1;
      [_, suffix] = $1.split("/");
      return "";
    }
  );
  const binaryString = atob(pureBase64ImageData);
  return [
    `--${boundary}`,
    `Content-Disposition: form-data; name="${name}"; filename="${filename}.${suffix}"`,
    `Content-Type: ${contentType}`,
    "",
    binaryString,
    `--${boundary}--`,
    "",
  ].join("\r\n");
};

/**
 * create boundary
 */
export const makeBoundary = () => {
  return `----CustomBoundary${(+new Date() * (1e16 * Math.random())).toString(
    36
  )}`;
};

/**
 * string to array buffer
 * @param {string} string
 */
export const string2ArrayBuffer = string => {
  const bytes = Array.prototype.map.call(string, c => {
    return c.charCodeAt(0);
  });
  return new Uint8Array(bytes).buffer;
};

export const editorInit = (el, otherBar = "", offset = 0) => {
  $.FE.LANGUAGE.zh_cn.translation.Des = "备注";

  $(el)
    .froalaEditor({
      key: "yE5F4B4A3jC10D7A5A5B2A3G3E2A2A3B-16hjulA-8oaqceF6nab==",
      heightMin: 400,
      heightMax: 800,
      language: "zh_cn",
      toolbarButtons: [
        otherBar,
        "fullscreen",
        "bold",
        "underline",
        "superscript",
        "subscript",
        "superscript",
        "|",
        "paragraphFormat",
        "align",
        "formatOL",
        "formatUL",
        "outdent",
        "indent",
        "|",
        "insertLink",
        "insertImage",
        "insertVideo",
        "insertTable",
        "|",
        "quote",
        "insertHR",
        "|",
        "undo",
        "redo",
        "clearFormatting",
        "html",
      ],
      // 图片上传配置
      imageUploadURL: "/attachment/upload",
      imageUploadRemoteUrls: false,
      imagePasteProcess: true,
      toolbarStickyOffset: offset,
      imageResizeWithPercent: true,
      imageDefaultWidth: 700,
      paragraphFormat: {
        H2: "Heading 1",
        H3: "Heading 2",
        H4: "Heading 3",
        Des: "Des",
        PRE: "Code",
        N: "Normal",
      },
      requestHeaders: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      },
    })
    .on("froalaEditor.image.loaded", (_, __, $img) => {
      const imageUrl = $img.attr("src");
      if (/^https:\/\/mmbiz.qpic.cn/.test(imageUrl)) {
        $.ajax({
          url: "/attachment/upload",
          method: "POST",
          data: {
            url: imageUrl,
          },
        }).done(res => {
          $img.attr("src", res.link);
        });
      }
    });
};
