import store from "@shared/scripts/utils/store";
import { openModalWithCb } from "@shared/scripts/actions/modal";

const switchBaseModal = () => {
  const $login = $(".sidebar-user__logout");

  const openFn = (cb = () => {}) => {
    store.dispatch(
      openModalWithCb("signin", () => {
        console.log("状态更改完成");
        cb();
        // 动画
        setTimeout(() => {
          console.log("visible change");
          $(".js-signin-modal").toggleClass("is-visible");
        });
      })
    );
  };

  if ($login.length === 0) {
    if (window.popupLogin) {
      openFn();
    }
  } else {
    if (window.currentUser && !window.currentUser.mobile_verified) {
      openFn();
    }
  }

  // const $window = $(window);
  const $sidebar = $(".js-sidebar");
  const $sidebarLayer = $(".sidebar__layer");

  const sidebarClose = () => {
    if ($sidebar.hasClass("is-visible")) {
      $sidebar.removeClass("is-visible");
      $(".js-switch-siderbar").removeClass("is-active");
    }

    if ($sidebarLayer.hasClass("is-visible")) {
      $sidebarLayer.removeClass("is-visible");
      $(".js-switch-siderbar").removeClass("is-active");
    }
  };

  // sidebar
  $(".js-switch-siderbar").on("tap", function(e) {
    e.stopPropagation();
    $sidebar.toggleClass("is-visible");
    $sidebarLayer.toggleClass("is-visible");
    $(this).toggleClass("is-active");
  });

  $sidebarLayer.on("tap", function(e) {
    e.stopPropagation();
    sidebarClose();
  });

  // search modal
  $(".js-switch-search").on("click", e => {
    e.stopPropagation();
    $(".js-search").toggleClass("is-visible");
    sidebarClose();
  });

  // signin modal
  $(".js-switch-sign").on("tap", e => {
    e.preventDefault();
    sidebarClose();
    if (
      $(".js-signin-modal")[0] &&
      $(".js-signin-modal")[0].className.includes("is-visible") &&
      window.popupLogin
    ) {
      return false;
    }
    openFn();
  });

  // forget modal
  $(".js-forget-mask").on("tap", e => {
    e.stopPropagation();
    $(".js-forget-modal").removeClass("is-visible");
  });
};

export default switchBaseModal;
