export const initWxCode = () => {
  const wxLogin = window.WxLogin;
  if (wxLogin) {
    setTimeout(() => {
      new wxLogin({
        self_redirect: false,
        id: "code-node",
        appid: "wx8c9a93acb12e2b2d",
        scope: "snsapi_login",
        redirect_uri: encodeURIComponent(
          `https://www.jiqizhixin.com/auth/wechat/callback?origin_url=${location.href}`
        ),
        state: Math.ceil(Math.random() * 1000),
        // 为1 href 会失效
        stylelite: 1,
        style: "black",
        href:
          "data:text/css;base64,LndlYl9xcmNvZGVfaW1nIHsgd2lkdGg6IDIwMHB4OyBoZWlnaHQ6IDIwMHB4IH0KLndlYl9xcmNvZGVfaW1nX3dycCB7IGJvcmRlcjogMXB4IHNvbGlkICNlMmUyZTIgfQo=",
      });
    });
  }
};
