import { pageScrollToTarget, clickAtOrigin } from "@shared/scripts/utils/tool";

const fixRightBottomList = () => {
  /* --readlater start-- */
  const $readlaterBtn = $("#js-read-later-btn");
  const $readlaterModal = $("#js-readlater-modal");
  $readlaterBtn.on("click", () => {
    $readlaterModal.toggleClass("is-show");
  });

  clickAtOrigin($readlaterBtn, () => {
    $readlaterModal.removeClass("is-show");
  });
  /* --readlater end-- */

  /* --backtop start-- */
  const $window = $(window);
  const wHeight = $window.height();
  const $backTop = $("#js-backtop");

  $window.on("scroll", () => {
    $backTop.toggleClass("is-show", Math.ceil($window.scrollTop()) > wHeight);
  });

  $backTop.on("click", () => {
    pageScrollToTarget();
  });
  /* --backtop end-- */

  /* -- position with scroll -- */
  const $fixRightList = $("#js-fix-right-list");
  const screenHeight = $window.height();

  $("#footer").offset() &&
    $window.on("scroll", () => {
      $fixRightList.toggleClass(
        "t-absolute",
        $window.scrollTop() > $("#footer").offset().top - screenHeight
      );
    });
};

export default fixRightBottomList;
