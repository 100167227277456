/* eslint-disable no-new */
import Swiper from "swiper";

const banner = () => {
  new Swiper("#js-post-slider", {
    loop: true,
    autoplay: {
      delay: 4000,
    },
    pagination: {
      el: ".post__page",
      type: "bullets",
      clickable: true,
    },
    // navigation: {
    //   nextEl: ".post__btn--next",
    //   prevEl: ".post__btn--prev",
    // },
  });
};

export default banner;
