const openDialog = ele => {
  ele.addClass("show");
  $(".code-modal__mask").addClass("show");
};

const closeDialog = () => {
  $(".code-modal__auto-byte").removeClass("show");
  $(".code-modal__science-ai").removeClass("show");
  $(".code-modal__mask").removeClass("show");
};

export default () => {
  $(".js-sideber-menu").on("click", function() {
    $(this).toggleClass("is-active");
  });

  $(".auto-byte").on("click", () => {
    openDialog($(".code-modal__auto-byte"));
    // document.body.style.overflow = "hidden";
  });

  $(".science-ai").on("click", () => {
    openDialog($(".code-modal__science-ai"));
    // document.body.style.overflow = "hidden";
  });

  $(".code-modal__mask").on("click", () => {
    closeDialog();
    document.body.style.overflow = "";
  });

  $(".code-modal__close").on("click", () => {
    closeDialog();
    document.body.style.overflow = "";
  });
};
