import "./styles/app.scss";
import "jquery";
import "zepto.touch";
import "jquery-form";
import Salert from "react-s-alert";
import RailsUJS from "rails-ujs";

import "jquery.qrcode";
import {
  runPage,
  bottomBarActionInPage,
  switchTab,
  timeago,
} from "@shared/scripts/utils/tool";
import "@shared/scripts/features/my.timeago";
import "@shared/scripts/features/iconfont";
import comment from "@shared/scripts/features/comment";
import insight_swiper from "@shared/scripts/features/insight_swiper";
import getCurrentUser from "@shared/scripts/features/get_current_user";
import fixRightBottomList from "./components/fix_right_bottom_list";
import home from "./components/home";
import daily from "./components/daily";
import article from "./components/article";
import search from "./components/search";
import topic from "./components/topic";
import column from "./components/column";
import document from "./components/document";
import event from "./components/event";
import member from "./components/member";
import industry from "./components/industry";
import modal from "./components/modal";
import catalog from "./components/catalog";
import readLater from "./components/read_later";
import articleEdit from "./components/article_edit";
import userInfo from "./components/user_info";
import joinForm from "./components/member_join_form";
import industrySolution from "./components/industry_solution";
import industryInstitution from "./components/industry_institution";
import insightModal from "./components/insight_modal";
import insight from "./components/insight";
import ncov from "./components/ncov";
import switchFn from "./components/switchFn.js";
import {
  openPostModal,
  submitColumnForm,
  generateQRcode,
  subHeaderAction,
  columnHeadeAction,
} from "./components/page_actions";

const application = () => {
  RailsUJS.start();
  getCurrentUser();

  // gloal ajax error
  $.ajaxSetup({
    headers: {
      "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
    },
    error: xhr => {
      if (xhr.responseJSON instanceof Array) {
        return;
      }
      Salert.error(xhr.responseText);
    },
  });

  fixRightBottomList();
  switchFn();

  runPage(
    [
      "memberships-category",
      "home-index",
      "insight-home",
      "insight-articles",
      "insight-documents",
    ],
    timeago
  );

  // membar sidebar dropdown
  const controllerName = $("body").data("controller");
  if (/^memberships$/.test(controllerName)) {
    member();
  }

  runPage(
    ["home-index", "dailies-index", "search-index", "trends-show"],
    modal
  );
  runPage(["documents-show", "articles-show", "setting-insight"], insightModal);
  runPage(["insight-articles", "insight-documents"], insight);
  runPage("home-index", home);
  runPage("insight-home", insight_swiper);
  runPage("dailies-index", daily);
  runPage("articles-show", article);
  runPage("documents-show", document);
  runPage(
    [
      "articles-show",
      "dailies-index",
      "reports-show",
      "documents-detail",
      "documents-show",
      "topics-show",
      "trends-show",
      "events-show",
    ],
    comment
  );
  runPage("articles-edit", articleEdit);
  runPage("events-show", event);
  runPage("search-index", search);
  runPage("topics-show", bottomBarActionInPage);
  runPage("topics-show", topic);
  runPage(
    [
      "solutions-show",
      "quests-show",
      "business_cases-show",
      "institutions-show",
    ],
    industry
  );
  runPage("setting-info", userInfo);
  runPage("setting-join", joinForm);
  runPage("read_laters-index", readLater);
  runPage("special_columns-show", column);
  runPage("industry-solutions", industrySolution);
  runPage("industry-institutions", industryInstitution);
  runPage(["technologies-show", "experts-show"], catalog);
  // TODO(wangpp): add 'special_columns-notice'
  runPage(
    [
      "home-index",
      "users-show",
      "setting-star",
      "setting-notice",
      "articles-index",
      "insight-home",
    ],
    switchTab
  );
  runPage("memberships-laboratory", openPostModal);
  runPage("special_columns-edit", submitColumnForm);
  runPage(
    ["articles-show", "dailies-show", "topics-show", "technologies-show"],
    generateQRcode
  );
  runPage(
    ["special_columns-index", "categories-show", "topics-index"],
    subHeaderAction
  );
  runPage(
    [
      "special_columns-setting",
      "special_columns-edit",
      "special_columns-notice",
      "articles-index",
      "articles-edit",
    ],
    columnHeadeAction
  );
  runPage("trends-show", ncov);
};

export default application;
