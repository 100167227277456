import React from "react";

import "antd/lib/popover/style/index.css";

import logoImg from "logo-black.png";
import emailImg from "email.png";
import badgeImg from "badge.png";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      xtList: [],
      count: 0,
      xtLoading: true,
      ranksList: [],
    };
  }

  componentDidMount() {
    const awards_url = window.location.origin;
    this.setState({
      ranksList: [
        {
          name: "2023 年度榜单",
          link:
            "https://www.jiqizhixin.com/short_urls/41640723-0077-46e7-aa2c-c2d67823c0d1",
        },
        {
          name: "2022 年度榜单",
          link:
            "https://www.jiqizhixin.com/short_urls/e098d7fb-3929-440c-9ecb-0e568106c325",
        },
        {
          name: "2021 年度榜单",
          link: `${awards_url}/awards/2021`,
        },
        {
          name: "2020 年度榜单",
          link: `${awards_url}/awards/2020`,
        },
        {
          name: "2019 年度榜单",
          link: `${awards_url}/awards/2019`,
        },
        {
          name: "2018 年度榜单",
          link: `${awards_url}/awards/2018`,
        },
        {
          name: "2017 年度榜单",
          link: "https://mp.weixin.qq.com/s/gw5KgjgK31YdFxlQYGbhsw",
        },
      ],
    });
  }

  render() {
    return (
      <div className="home__bottom">
        <div className="home__bottom__first">
          <div className="home__bottom__first-left">
            <img src={logoImg} alt="logo" />
            <div className="home__bottom__first-item home__bottom__first-item--ranks">
              <div className="home__bottom-ranks">
                {this.state.ranksList.length > 0 &&
                  this.state.ranksList.map((item, index) => (
                    <div
                      className="home__bottom-ranks-item"
                      key={index}
                      onClick={() => window.open(item.link, "_blank")}
                    >
                      {item.name}
                    </div>
                  ))}
                <div className="home__bottom-ranks-triangle" />
              </div>
              <p>年度榜单</p>
            </div>
            <div
              className="home__bottom__first-item"
              onClick={() =>
                window.open(
                  "https://app6ca5octe2206.pc.xiaoe-tech.com/",
                  "_blank"
                )
              }
            >
              <p>学堂</p>
            </div>
          </div>
          <div className="home__bottom__first-right">
            寻求合作
            <img src={emailImg} alt="email" />
            zhaoyunfeng@jiqizhixin.com
          </div>
        </div>
        <div className="home__bottom__second">
          <div className="home__bottom__second-left">
            <img src={badgeImg} alt="badge" />
            <a href="https://beian.miit.gov.cn" target="_blank">
              京ICP备2021005318号-1
            </a>{" "}
            ©{new Date().getFullYear()}
            机器之心（北京）科技有限公司
          </div>
          <div className="home__bottom__second-right">
            <a
              href="https://www.jiqizhixin.com/short_urls/f7dd70ed-41d4-47c0-ad8f-5871ed7a03c8"
              target="_blank"
            >
              用户协议
            </a>
            <a
              href="https://www.jiqizhixin.com/short_urls/d3aecbce-803e-42f9-8d6c-8ee34e57cb12"
              target="_blank"
            >
              隐私政策
            </a>
            <a href="https://www.jiqizhixin.com/copyright" target="_blank">
              免责声明
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
