import switchBaseModal from "./switch_base_modal.js";
import siteHeader from "./site_header";
import header from "./header";
import { action, m_action } from "./add_like_action";

export const isMobile = () => {
  return document.body.clientWidth < 768;
};

export const addWindowQueue = name => {
  window[`${name}Queue`] = [];
};

addWindowQueue("resizeEvent");

export const addResizeListener = (callback, immediate = true) => {
  const handleResize = isFirst => {
    // 调用回调函数，并传入当前是否是移动端的布尔值 (不清楚为什么第一时间拿不到，需要等一下re-render)
    if (isFirst) {
      window.resizeEventQueue.forEach(fn => fn(isMobile()));
    } else {
      setTimeout(() => {
        window.resizeEventQueue.forEach(fn => fn(isMobile()));
      });
    }
  };

  // 监听窗口的 resize 事件
  if (window.resizeEventQueue.length === 0) {
    window.addEventListener("resize", handleResize, false);
  }
  window.resizeEventQueue.push(callback);

  // 立即执行一次，以确保在屏幕尺寸变化前也能获取到当前状态
  immediate && handleResize(true);

  // 返回一个函数，用于取消监听
  return () => {
    window.removeEventListener("resize", handleResize, false);
  };
};

/**
 * 响应式手机端判定
 * @returns
 */
export const reactiveIsMobile = callback => {
  addResizeListener(callback);
};

const switchFn = (options = {}) => {
  let isPcExecuted = false;
  let isMobileExecuted = false;

  const { pcCallback, mobileCallback } = {
    pcCallback: () => {
      siteHeader();
      m_action();
    },
    mobileCallback: () => {
      header();
      action();
    },
    ...options,
  };

  switchBaseModal();
  reactiveIsMobile(res => {
    const pH = document.querySelector(".pc-header");
    const mH = document.querySelector(".mobile-header");
    const mB = document.querySelector(".mobile-sidebar");
    if (res) {
      pH.style.zIndex = "-100";
      mH.style.zIndex = "100";
      mB.style.display = "flex";
      if (!isMobileExecuted) {
        mobileCallback();
        isMobileExecuted = true;
      }
    } else {
      pH.style.zIndex = "100";
      mH.style.zIndex = "-100";
      mB.style.display = "none";
      if (!isPcExecuted) {
        pcCallback();
        isPcExecuted = true;
      }
    }
  });
};

export default switchFn;
